<template>
  <Loader :value="promises.fetch" theme="default" class="project-page">
    <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import CustomComponents from '@/components/Custom'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/project'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('project')],

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__text ',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView(
        '.section-info__content',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .kpb-images-text-section:nth-child(2) {
    .kpb-section__container {
      @include respond-below('phone') {
        display: flex;
        flex-direction: column-reverse;
        padding: 0rem;
        padding-bottom: 1rem;

        .kpb-images-text-section__content {
          padding: 0rem 1rem;
        }
      }
    }
  }

  .hero__text {
    top: 0;
    left: 0;
    position: absolute;
    bottom: initial;
    right: initial;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: clamp(1rem, 1.4rem, 2rem);
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));

    &-placement {
      &--center {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      &--left {
        align-items: flex-start;
        justify-content: center;

        a:first-child {
          margin-left: 0;
        }

        .kpb-text {
          text-align: left;
          margin-left: 10vw;

          @include respond-below('phone') {
            margin-left: 1rem;
          }
        }
      }
      &--none {
        display: none;
      }
    }
  }

  .hero__text {
    padding: 0 1rem;
    & > * {
      padding: 0rem 1rem;
    }

    h1 {
      margin-bottom: clamp(1rem, 2rem, 3rem);
      font-size: clamp(1rem, 13vw, 3.5rem);
      margin-bottom: 1rem;
    }
    p {
      margin-top: 0;
    }

    .elder-button__label {
      font-size: 1.1rem;
      padding: 0.9rem 1.25rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;

        font-size: 0.8rem;
      }
    }
    .elder-button--icon-right .elder-button__icon {
      padding: 0.9rem 1.25rem 0.9rem 0;
      font-size: 1.1rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }
    a {
      margin: 0 0.5rem;
      &[data-cta='primary'] {
        background: white;
        color: black;
        font-weight: 500;
        &:hover {
          background: rgba(white, 0.9);
        }
      }
      &[data-cta='secondary'] {
        font-weight: 500;
        background: transparent;
        color: white;
        border: 1px solid white;
        &:hover {
          background: rgba(black, 0.2);
        }
      }
    }
  }

  .section-hero {
    margin-block-end: 1rem;
  }

  .kpb-stats {
    &__label {
      color: var(--secondary-contrast);
    }
    &__value {
      font-weight: 700;
      color: var(--primary);
    }
  }
}
</style>
