<template>
  <Residentials
    :filter="filter"
    :project="project"
    :properties="properties"
    :disable-navigation="true"
    v-bind="$attrs"
  >
    <template #row-end="{ item }">
      <div class="residentials-actions">
        <a
          v-if="getFloorplan(item).url"
          class="residentials-actions__item"
          :href="getFloorplan(item).url"
          target="_blank"
        >
          <FontAwesomeIcon :icon="['fad', 'file-pdf']" size="2x" />
          <span>{{ $t('lookAt', { x: $tc('floorplan', 1).toLowerCase() }) }}</span>
        </a>
      </div>
    </template>
  </Residentials>
</template>

<script>
import API from '@/api'
import Bus from '@/bus'

import Residentials from '@kvass/residentials/src/Component'

export default {
  data() {
    return {
      filter: {},
      project: API.project,
    }
  },
  computed: {
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  methods: {
    getFloorplan(item) {
      return this.$path('media.floorplan.0', item) || {}
    },
  },
  created() {
    Bus.on('residentials:filter', filter => (this.filter = filter))
  },
  components: {
    Residentials,
  },
}
</script>

<style lang="scss">
.residentials {
}
</style>
