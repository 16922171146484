<template>
  <img ref="el" class="floating-pattern" src="/floating-pattern.svg" alt="" />
</template>

<script>
import { scroll } from 'motion'

export default {
  methods: {
    position(y) {
      return `${(y.scrollLength / 2) * y.progress + y.scrollLength / 3}px`
    },
  },
  mounted() {
    scroll(({ y }) => {
      if (!this.$refs.el) return
      this.$refs.el.style.top = this.position(y)
    })
  },
}
</script>

<style lang="scss">
.floating-pattern {
  width: 400px;
  position: absolute;
  top: 50%;
  translate: -30%;
  pointer-events: none;

  @include respond-below('phone') {
    display: none;
  }
}
</style>
