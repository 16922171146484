<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__menu">
        <template v-for="(item, index) in navigation">
          <ActionComponent
            v-if="$path('action.name', item) !== $path('name', $route)"
            class="footer__menu-action"
            :class="{ 'footer__menu-action--empty': !item.action }"
            v-bind="item"
            :key="index"
          />
          <template v-if="item.items && item.items.length">
            <ActionComponent
              v-for="(nested, ni) in item.items"
              class="footer__menu-action footer__menu-action--indent"
              v-bind="nested"
              :key="index + '_' + ni"
            />
          </template>
        </template>
      </div>
      <div class="footer__policy">
        <router-link :to="`/api/legal/privacy/project/${item.id}`" target="_blank">
          <FontAwesomeIcon :icon="['far', 'lock-alt']" size="1x" class="footer__icon" />
          {{ $t('privacyPolicy') }}
          /
          {{ $t('cookiePolicy') }}
        </router-link>
        <a    
        href="https://malling.no/forbehold"
        target="_blank">
          <FontAwesomeIcon :icon="['far', 'file-alt']" class="footer__icon" size="1x" />
          {{ disclaimer.title }}
        </a>
        <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        <div class="footer__copyright">
          &copy; {{ year }} - {{ $t('pageMadeBy') }}
          <a :href="`https://kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
        </div>
      </div>
      <router-link to="/" @click.native="scrollToTop">
        <img :src="logo" class="footer__logo" :alt="item.name" />
      </router-link>
    </div>
    <div class="container footer__container-bottom">
      Denne prospektnettsiden er utarbeidet av Pulse Communications og Kvass på oppdrag av Investire
      AS og Malling & Co Markets
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import ActionComponent from 'vue-elder-navigation/src/action'
import API from '@/api'
import FooterContent from '@kvass/template-footer-content'
import { GetCustomField, ScrollToAnchor } from '@/utils'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    address() {
      return this.item.address
    },

    disclaimer() {
      if (!this.item.posts) return

      const disclaimer = this.item.posts.find(p => p.customFieldsConfig[0].source === 'disclaimer')

      return disclaimer
    },

    rentPageMadeBy() {
      let company = GetCustomField('company', this.item.customFields) || {}
      if (!company) return

      return this.$t('rentPageMadeBy', {
        by: `<a href="https://www.kvass.no?ref=${this.currentUrl}">Kvass</a>`,
        company: `<a href=${company.url || '/'} > ${company.name || this.$path('item.name')}</a>`,
      })
    },
    currentUrl() {
      return window.location.href
    },

    logo() {
      return API.logo
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    navigate() {
      ScrollToAnchor('lead')
    },
  },
  components: {
    ActionComponent,
    FooterContent,
  },
}
</script>

<style lang="scss">
.footer {
  --space: 5.75rem;
  --item-gap: 1.1rem;

  background-color: css-lighten('secondary', 25%);
  color: var(--secondary-contrast);

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.25rem var(--space);
    max-width: 1200px;

    @include respond-below('phone') {
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  .elder__navigation-component {
    line-height: 1.2;
    text-underline-offset: 3px;
    background: none;
  }

  a {
    color: var(--secondary-contrast);
    line-height: 1.2;
    text-underline-offset: 3px;
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
  }

  &__container-bottom {
    font-size: 0.875rem;
    padding-block: 1rem 3rem !important;
    text-align: center;
    background-color: css-lighten('secondary', 25%);
    border-top: 1px solid;
  }

  &__logo {
    max-width: 200px;

    @include respond-below('phone') {
      order: 1;
    }
  }

  &__icon {
    margin-right: 10px;
  }

  &__policy {
    display: grid;
    gap: var(--item-gap);
    grid-template-columns: 1fr;

    @include respond-below('phone') {
      order: 2;
      text-align: center;
    }
  }

  &__menu {
    column-count: 2;
    column-gap: var(--space);

    @include respond-below('phone') {
      column-count: unset;
      display: flex;
      flex-direction: column;
      order: 2;
    }

    .elder__navigation-component-icon-right {
      display: none;
    }

    &-action {
      line-height: 20px;
      margin: 0;
      padding: 0;
      background-color: transparent;
      color: inherit;
      font-weight: inherit;
      align-items: flex-start;
      margin-bottom: var(--item-gap);

      @include respond-below('phone') {
        align-self: center;
        text-align: center;
        justify-content: center;
        align-items: center;
      }

      & div:hover {
        text-underline-offset: 5px;
        text-decoration: underline !important;
        text-decoration-color: var(--primary-contrast);
        cursor: pointer;
      }

      &--indent {
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
