import { transformContent, getValueFromArray } from '@/utils'

export default function () {
  const get = path => this.$path(path, this.$store.state)

  function getCoverText(title, content) {
    if (title) return `<h1>${title}</h1> ${content}`

    return content
  }

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Banner',
            props: {
              class: 'hero',
              style: 'position: relative',
              backgroundColor: 'white',
            },
            data: {},

            blocks: {
              default: [
                {
                  component: 'Slider',
                  data: get('Project.item.media.cover'),
                  props: {
                    aspectRatio: '16/9',
                    class: 'hero__slider',
                    style: 'position: relative',
                    options: {
                      manualNavigation: false,
                    },
                  },
                  blocks: {
                    bottom: [
                      {
                        component: 'Flex',
                        condition:
                          get('Project.item.customFields.project-hero.title') ||
                          get('Project.item.customFields.project-hero.content') ||
                          (get('Project.item.customFields.project-hero-buttons') || []).filter(
                            f => {
                              return f.content && f.type
                            },
                          ).length,
                        props: {
                          class: `hero__text hero__text-placement--${get(
                            'Project.item.customFields.project-hero.placement',
                          ) || 'center'}`,
                          justifyContent: 'center',
                          flexDirection: 'column',
                        },
                        blocks: {
                          default: [
                            {
                              component: 'Text',
                              condition:
                                get('Project.item.customFields.project-hero.title') ||
                                get('Project.item.customFields.project-hero.content'),
                              props: {
                                textAlign: 'center',
                                style: 'margin-bottom: 1rem',
                              },
                              data: {
                                content: getCoverText(
                                  get('Project.item.customFields.project-hero.title'),
                                  get('Project.item.customFields.project-hero.content'),
                                ),
                              },
                            },
                            {
                              component: 'Flex',
                              condition: (
                                get('Project.item.customFields.project-hero-buttons') || []
                              ).filter(f => {
                                return f.content && f.type
                              }).length,

                              props: {
                                alignItems: 'center',
                              },
                              blocks: {
                                default: [
                                  {
                                    component: 'Text',
                                    data: {
                                      content: (
                                        get('Project.item.customFields.project-hero-buttons') || []
                                      )
                                        .filter(button => {
                                          if (button?.type === 'brochure') return
                                          return Boolean(button.content)
                                        })
                                        .map(
                                          button =>
                                            `<a target="_self" data-cta="${button.type === 'flatfinder' ? 'secondary' : 'primary'
                                            }" href="#${button.type}">${button.content}</a>`,
                                        )
                                        .join(''),
                                    },
                                  },
                                  {
                                    component: 'Brochure',
                                    condition:
                                      (get('Project.item.media.brochure') || []).length &&
                                      getValueFromArray(
                                        get('Project.item.customFields.project-hero-buttons'),
                                        'type',
                                        'brochure',
                                      ).type,
                                    data: get('Project.item.media.brochure'),
                                    props: {
                                      style: 'padding-top: auto;',
                                      placement: 'top',
                                      label: getValueFromArray(
                                        get('Project.item.customFields.project-hero-buttons'),
                                        'type',
                                        'brochure',
                                      ).content,
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
              bottom: {
                component: 'Stats',
                data: {
                  content: get('Project.item.customFields.key-value'),
                },
                condition: Boolean(get('Project.item.customFields.key-value.0.title')),
                variant: 'split',
                props: {
                  class: 'stats',
                  padding: '1rem',
                  width: 'default',
                  theme: 'secondary',
                  transform: 'vertical',
                  width: 'medium',
                },
              },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.description'),
      props: {
        class: 'project-info',
      },

      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: get('Project.item.customFields.project-hero.title')
                ? get('Project.item.description')
                : transformContent(get('Project.item.description')),
            },
            props: {
              textAlign: 'center',
            },
          },
        ],
      },
    },
    ...(get('Project.item.customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'] || 'outside'
        },
      }
    }),

    {
      component: 'ImagesTextSection',
      condition: get('Project.item.media.gallery.length'),
      props: {
        class: 'project-gallery',
        theme: 'default',
        captionPlacement: 'outside'
      },
      data: {
        content: {
          images: get('Project.item.media.gallery'),
        },
      },
    },

    {
      component: 'Section',
      condition:
        get('Project.item.customFields.cta.content') || get('Project.item.customFields.cta.button'),
      props: {
        class: 'cta-bottom ',
        style: 'padding-block: 3.5rem;',
        theme: get('Project.item.customFields.cta.invert') ? 'white' : 'primary'
      },

      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              alignItems: 'center',
              class: 'cta-bottom__content',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  data: {
                    content: get('Project.item.customFields.cta.content'),
                  },
                  props: {
                    textAlign: 'center',
                  },
                },
                {
                  component: 'Button',
                  data: {
                    label: get('Project.item.customFields.cta.button'),
                    to: get('Project.item.customFields.cta.link') ?? { name: 'Contact' },

                    tag: 'router-link',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
