<template>
  <div
    :style="`--custom-background-color: ${customColor}; --custom-body-color: ${customBodyColor}`"
    id="app"
    class="route"
    :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
  >
    <div class="transition">
      <img class="transition__img" :src="logo.src || logo" />
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        {
          ['nav--expanded']: isExpanded,
          [`nav--${view.toLowerCase()}-${slug}`]: view.toLowerCase() == 'post',
        },
      ]"
    >
      <NavigationComponent
        :logo="logo"
        :items="items"
        :title="projectName"
        padding="20px 0"
        :breakAt="breakAt"
        :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
        :isOpen.sync="isExpanded"
      >
      </NavigationComponent>
    </div>

    <transition mode="out-in" name="route">
      <router-view />
    </transition>

    <FooterComponent
      v-if="$route.meta.footer !== false"
      :navigation="items.filter(i => i.class !== 'elder__navigation-component--policy')"
      :cookie-manager="CM"
    />
    <AssetComponent />
    <FloatingPattern :key="$route.fullPath" />
  </div>
</template>

<script>
import { NavigationComponent } from 'vue-elder-navigation'
import { mapState, mapActions } from 'vuex'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import FloatingPattern from '@/components/FloatingPattern'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from '@/gdpr'

const breakAt = null

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
      breakAt: breakAt,
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    slug() {
      return this.$route.params.slug
    },
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    projectLanguages() {
      return this.$path('customFields.project-languages', this.item)
    },

    logo() {
      // if (this.$route.name === 'Project' && !this.isSingular)
      //   return this.isOnTop ? this.logos.dark : this.logos.light
      return this.logos.light
    },
    isDemo() {
      return this.item.demo || false
    },
    posts() {
      return this.item.posts || []
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },

    customColor() {
      if (!this.$path('customFields.custom-color.show-color', this.item)) return '#F1F1E5;'
      return this.$path('customFields.custom-color.color', this.item) || '#F1F1E5'
    },
    customBodyColor() {
      if (!this.$path('customFields.custom-color.show-contrast-color', this.item)) return 'black;'
      return this.$path('customFields.custom-color.contrast-color', this.item) || 'black;'
    },

    customColor() {
      return this.$path('customFields.custom-color', this.item) || '#F1F1E5'
    },

    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },

    disclaimer() {
      if (!this.item.posts) return

      const disclaimer = this.item.posts.find(p => p.customFieldsConfig[0].source === 'disclaimer')
      return disclaimer
    },
    mainAction() {
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },

    defaultItems() {
      return [
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            label: i.title,
            exact: true,
            class: 'elder__navigation-component--item',
            order: this.$path('customFields.page-settings.menu-order', i) * 10 - 5,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
          }
        }),
        {
          label: this.$t('leadFormTitle'),
          action: this.redirect('Contact'),
          id: 'contact',
          order: 1000,
        },

        {
          alignment: 'left',
          icon: ['far', 'lock-alt'],
          label: this.$t('privacyPolicy'),
          class: 'elder__navigation-component--policy',
          action: `/api/legal/privacy/project/${this.item.id}`,
          order: 1001,
          id: 'policy',
          show: this.isExpanded,
        },
        {
          condtion: this.disclaimer,
          alignment: 'left',
          icon: ['far', 'file-alt'],
          label: this.disclaimer?.title,
          class: 'elder__navigation-component--policy',
          action: this.disclaimer?.slug,
          order: 1002,
          id: 'policy',
          show: this.isExpanded,
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function () {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
    FloatingPattern,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.transition {
  @keyframes PageLoadTransition {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes hideAnimation {
    to {
      display: none;
      width: 0;
      height: 0;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primary);
  position: fixed;
  height: 100vh;
  margin: 0;
  width: 100%;
  opacity: 1;
  z-index: 10000;

  animation: PageLoadTransition 1s 2s forwards, hideAnimation 0s forwards 3s;

  &__img {
    @keyframes PageLoadImage {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    max-width: 14vw;
    width: 100%;
    object-fit: contain;
    opacity: 0;
    animation: PageLoadImage 2s 0s forwards;
  }
}

.nav {
  position: sticky;
  top: 0;
  width: 100%;
  padding-inline: 1.5rem;
  z-index: get-layer('nav');
  background: var(--primary);
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  color: var(--primary-contrast);
  transition: background 150ms ease-in;

  .elder__navigation-bars {
    color: var(--primary-contrast);
  }

  .elder__navigation {
    &-logo {
      .elder__navigation-component {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &-bars {
      &:hover {
        cursor: pointer;
      }
    }

    &-actions {
      align-items: center;

      .elder__navigation-component {
        color: var(--primary-contrast);
        font-weight: 500;
        position: relative;
        transition-duration: 100ms;
        transition-timing-function: var(--easing-function);

        &:hover {
          background: none;
          cursor: pointer;

          &:after {
            content: '';
            background-color: var(--secondary);
            width: calc(100% - 2rem);
            height: 1px;
            position: absolute;
            left: 1rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    &--responsive {
      padding: 20px 0 !important;

      .elder__navigation-component {
        justify-content: center;
        width: initial;
      }

      [data-id='policy'] {
        align-self: center;
        justify-self: center;
        padding: 1.5rem 0 !important;
        max-width: 100px;

        .elder__navigation-component {
          display: block;
          padding: 0 !important;
        }
      }

      [data-id='contact'] {
        padding-top: 1.5rem !important;
      }
    }

    [data-id='contact'] {
      .elder__navigation-component {
        font-weight: 500;
        padding: 0.4rem 0.75rem;
        background-color: var(--secondary) !important;
        color: var(--secondary-contrast) !important;

        & > span {
          margin-top: 0.1rem;
        }

        &:hover {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &-bars {
    color: white;
  }

  &--expanded {
    height: 100vh;

    .elder__navigation-actions {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 2rem;

      .elder__navigation-node {
        padding: 0.5rem 2rem;
        width: 100%;
        justify-content: center;
        display: flex;

        &:nth-child(1) {
          padding-top: 2rem;
        }
      }
    }

    .elder__navigation-actions > * {
      justify-content: center;
      text-align: center;
    }
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
